<template >
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
      <el-breadcrumb-item>金融超市</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="concent clearfix">
       <section class="section3">
        <div class="chartView clearfix">
          <div class="top clearfix">
            <div class="title fl">金融平台</div>
            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt="" class="img fr toMore" />
          </div>
          <div class="center ">
            <div class="bank-icon fl fl-c-c" style="width: 25%" v-for="(item, index) in platform" :key="index" @click="go(item,1)">
             <div style="padding:10px 20px;background-color:#4d79ff;border-radius:10px;">
                <img  style="height:23px;width:150px" :src="item.src" alt="" />
             </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section3">
        <div class="chartView clearfix">
          <div class="top clearfix">
            <div class="title fl">银行机构</div>
            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt="" class="img fr toMore" />
          </div>
          <div class="center">
            <div class="bank-icon fl fl-c-c" style="width: 25%" v-for="(item, index) in banks" :key="index">
              <img :src="item.src" alt="" />
            </div>
          </div>
        </div>
      </section>

   <section class="section3">
        <div class="chartView clearfix">
          <div class="top clearfix">
            <div class="title fl">金融产品</div>
            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt="" class="img fr toMore" />
          </div>
          <div class=" grid col-3    ">
            <div  v-for="(item, index) in financialProduct" :key="index" class='padding-sm'>
                 <div class="product-card" >
            <div>
                <img class="logo" :src="item.src" alt="" />
            <div class="name">{{item.name}}</div>
             </div>
              <div>
                   <div class="desc"><div class="name">贷款对象</div><div v-html="item.facing"></div> </div>
                   <div class="desc"><div class="name">产品特点</div><div>{{item.characteristics}}</div></div>
                   <div class="desc"><div class="name">贷款额度</div><div>{{item.quota}}</div> </div>
                   <div class="desc"><div class="name">还款期限</div><div>{{item.repay}}</div> </div>
              </div>
              <div class="fl f-b footer">
                 <el-button v-for="(btn,bindex) in item.btns" :key="bindex" type="primary" @click="handleButtonClick(btn,true,item)" round>{{btn.name}}</el-button>
              </div>
            </div>
            </div>
           
          </div>
        </div>
      </section>

      <el-table-plus title="申请记录" 
      :columns="columns" 
      :search-options='searchOptions'
      :size="5"
      :apis="{list:'/bankcredit/findFinancialMarketApplyPage'}"
      
      > </el-table-plus>
     
    </div>
    <el-dialog title="贷款预约" :visible.sync="showFormDialog" width="65vw">
        <el-form-plus v-model="form" confirmButtonText="提交" :structure="[
          {label:'被授信银行',key:'authorizedBank',disabled:true},
          {label:'授信企业',key:'creditEnterprise',disabled:true},
          {label:'被授信产品',key:'creditGrantedProducts',disabled:true},
          {label:'信用代码',key:'socialCode',disabled:true},
          {label:'申请人',key:'applicant',required:true},
          {label:'申请金额',type:'float',append:'万元',key:'applicationAmount',required:true},
          {label:'手机号',key:'phone',smsApi:'/bankcredit/sendVerificationCode',type:'sms',required:true},
          {label:'验证码',key:'smsVerificationCode',required:true},
        ]" @cancel="showFormDialog = false" @submit="handleShowAgreementDialog"></el-form-plus>
    </el-dialog>
    <el-dialog title="贷款协议" :visible.sync="showAgreementDialog" >
        <div v-html="agreement"></div>
        <template v-slot:footer>
           <div class=""> 
              <el-button  @click="showAgreementDialog =false">取消</el-button>
              <el-button type="primary" @click="handleSubmit">同意</el-button>
           </div>
        </template>
    </el-dialog>
  </div>
</template>
<script>
//
import { mapState } from 'vuex';
import ElFormPlus from "@/components/el-form-plus/index.vue"
import ElTablePlus from "@/components/el-table-plus.vue"
import { $post } from '@/http/axios';
import agreement from "./agreement"
export default {
  name: 'demandDetails',
  components: {ElFormPlus,ElTablePlus},
  data() {
    return {
      showFormDialog:false,
      agreement,
      showAgreementDialog:false,
      form:{},
      // {creditEnterprise=授信企业,authorizedBank=被授信银行,creditGrantedProducts=被授信产品,creditTime=授信时间,
      //applicant=申请人,applicationAmount=申请金额,phone=申请人手机号,amount=融资金额,startDate=合同起始日期,endDate=合同到期日期,
      //interestRate=利率,nameLoanProduct=贷款产品名称,status=贷款状态(1-通过，2-拒绝)}
      columns:[
            { label: "申请产品",prop: "creditGrantedProducts"},
            { label: "申请银行",prop: "authorizedBank"},
            { label: "申请人",prop: "applicant"},
            { label: "申请人手机号",prop: "phone"},
            { label: "申请金额",prop: "applicationAmount",formatter:val=>val ? `${val}万元` :'-'},
            { label: "产品利率",prop: "interestRate",formatter:val=>val ? `${val}%` :'-'},
            { label: "状态",prop: "status"},
            { label: "授信时间",prop: "creditTime"},
        ],
      searchOptions:
        [
            { label:'产品名称',type:'text',key:'creditGrantedProducts'},
            { label:'银行名称',type:'text',key:'authorizedBank'},
            { label:'申请状态',type:'select',key:'status',options:[
              {label:'通过',value:1},
              {label:'拒绝',value:2}
            ]},
        ],
      /** @desc 银行 */
      banks: [
        { src: require('@/assets/img/bank/logo-hxyh.png'), url: '' },
        { src:require('@/assets/img/bank/dtnsyh.jpg') },
        { src: require('@/assets/img/bank/lwnsyh.jpg'), url: 'https://app.lwrcb.com/financialMarket/index.html' },
        { src: require('@/assets/img/bank/zgjsyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zggsyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zgyzyh.jpg'), url: '' },
        { src: require('@/assets/img/bank/zgyh.jpeg'), url: '' },
        { src: require('@/assets/img/bank/zsyh.jpg'), url: '' },
        // { src: require('@/assets/img/bank/hzyh.jpeg'), url: '' },
      ],
      /** @desc 金融平台 */
      platform:[
         { src:"http://www.wzjrfw.com/static/img/logo@2x.0a76ddd.png", url: 'https://www.wzjrfw.com' },
      ],
      /** 金融产品 */
      financialProduct:[
        { 
          src: require('@/assets/img/bank/logo-hxyh.png'),
          bank:"华夏银行",
          name:"房贷通",
          // 面向对象
          facing:"小微企业",
          // 特点
          characteristics:"房贷通是指为满足小微企业实际生产经营融资需求，对能提供产权明晰、变现能力较强的房产抵押，且符合我行准入要求的小微企业发放贷款的业务",
          // 额度
          quota:"3000万元",
          //还款期限
          repay:"1-120月",
          btns:[
            {name:"贷款预约",action:this.handleShowDialog}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        { 
          src: require('@/assets/img/bank/logo-hxyh.png'),
          bank:"华夏银行",
          name:"技改贷",
          // 面向对象
          facing:"小微企业",
          // 特点
          characteristics:"技改贷是指向温州地区的小微企业提供的，用于解决其技术改造及产业升级期间资金需求的贷款业务。",
          // 额度
          quota:"最高限额200万元",
          //还款期限
          repay:"1-12月",
          btns:[
            {name:"贷款预约",action:this.handleShowDialog}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        { 
          src: require('@/assets/img/bank/logo-hxyh.png'),
          bank:"华夏银行",
          name:"资能贷",
          // 面向对象
          facing:"小微企业",
          // 特点
          characteristics:"资能贷业务是我行把小微园区内优质企业所拥有的有形资产、无形资产、各种数据，综合分析转化为融资额度，用于短期生产经营周转的可循环的贷款。",
          // 额度
          quota:"最高限额300万元",
          //还款期限
          repay:"1-12月",
          btns:[
            {name:"贷款预约",action:this.handleShowDialog}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        { 
          src: require('@/assets/img/bank/logo-hxyh.png'),
          bank:"华夏银行",
          name:"自建厂房按揭",
          // 面向对象
          facing:`温州地区非<a href='https://baike.baidu.com/item/%E4%B8%A4%E9%AB%98%E4%B8%80%E5%89%A9%E8%A1%8C%E4%B8%9A/9505813?fromtitle=%E4%B8%A4%E9%AB%98%E4%B8%80%E5%89%A9&fromid=22169809&fr=aladdin'>两高一剩</a>行业小企业`,
          // 特点
          characteristics:"自建厂房按揭贷款是指向温州地区非“两高一剩”行业的小企业借款人提供的，用于解决其自主建设自用厂房以及购买设备资金需求的贷款业务。",
          // 额度
          quota:"最高限额2000万元",
          //还款期限
          repay:"1-60月",
          btns:[
            {name:"贷款预约",action:this.handleShowDialog}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        { 
          src: require('@/assets/img/bank/logo-hxyh.png'),
          bank:"华夏银行",
          name:"银保通",
          // 面向对象
          facing:"小微企业",
          // 特点
          characteristics:"银保通业务是指小微企业以信用风险作为保险标的向保险公司投保，在获得保险公司对其提供保险的情况下，我行向借款人发放贷款。",
          // 额度
          quota:"最高限额300万元",
          //还款期限
          repay:"1-12月",
          btns:[
            {name:"贷款预约",action:this.handleShowDialog}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        { 
          src: require('@/assets/img/bank/dtnsyh.jpg'),
          bank:"洞头农商银行",
          name:"小微普贷",
          // 面向对象
          facing:"在瓯江口有固定经营场所且正常生产经营的企业或其他经济组织",
          // 特点
          characteristics:"借助大数据技术和省金综平台等信贷数据做为提额标准的一款信贷产品。纯信用，无抵押、无担保，循环使用，随借随还",
          // 额度
          quota:"按参保人数、厂房资产、法人资产和纳税四个维度进行不同等级的提额，最高贷款额度不超过300万元。",
          //还款时间
          repay:"贷款用信期限最长为1年，授信最长为3年",
          btns:[
            
            {name:"开户预约",url:"https://wx.dtnsbank.com/panwx/#/YYKH?loanType=1"},
            {name:"贷款预约",url:"https://wx.dtnsbank.com/panwx/#/YYDK?loanType=1"}
          ]
        },
        { 
          src: require('@/assets/img/bank/dtnsyh.jpg'),
          bank:"洞头农商银行",
          name:"税·费惠贷",
          // 面向对象
          facing:"在瓯江口有固定经营场所且正常生产经营的企业或其他经济组织",
          // 特点
          characteristics:"以企业生产经营中产生的税款、水电费、代发工资金额确定授信额度，用于缴纳税款、水电费、发放工资等用途的贷款，纯信用，无抵押、无担保",
          // 额度
          quota:"按企业前三个月税款、水电费、代发工资金额合计确定，佐证材料以企业纳税申报表、发票、转账记录等或在我行代发、代扣数据为准",
          //还款期限
          repay:"贷款用信期限最长为1年，授信最长为3年",
          btns:[
            
            {name:"开户预约",url:"https://wx.dtnsbank.com/panwx/#/YYKH?loanType=2"},
            {name:"贷款预约",url:"https://wx.dtnsbank.com/panwx/#/YYDK?loanType=2"}
          ]
        },
        { 
          src: require('@/assets/img/bank/lwnsyh.jpg'),
          bank:"龙湾农商银行",
          name:"赋能速贷",
          // 面向对象
          facing:"瓯江口产业聚集区安全（应急）产业生产体系的发展企业",
          // 特点
          characteristics:"“1+2+2”+X贷款方式，启动即可获100万元贷款（无需签字，线上一键操作）；后续进入孵化和试验阶段，各阶段均可申请200万元贷款。纯信用、无抵押、无担保，循环使用、随借随还；",
          // 额度
          quota:"500万元",
          //还款期限
          repay:"1-12月",
          btns:[
            {name:"开户预约",url:`https://app.lwrcb.com/financialMarket/appointment.html?custName=$COMPANY_NAME&custId=$CODE`}
            // {name:"贷款预约",url:"https://app.lwrcb.com/financialMarket/appointment.html?custName=&custId="}
          ]
        },
        
      ],
      list: [],
      
    };
  },
  computed: {
    ...mapState({
      userStore: (state) => state.userStore ,
    }),
  },
  methods: {
    handleShowDialog(item){
      this.showFormDialog = true
      this.form ={
        authorizedBank:item.bank,
        creditGrantedProducts:item.name,
        creditEnterprise:this.$store.state.userStore.adminVo.companyName,
        socialCode:localStorage.getItem("SOCIAL_CODE")

      }
      console.log(`显示授信弹窗`,item)
    },
    handleShowAgreementDialog(){
       this.showAgreementDialog = true;
    },
    async handleSubmit() {
      console.log(`表单提交111`,this.form);

      let {success,message} = await $post('/bankcredit/editBankCredit',this.form);
      if(success && message.indexOf('失败') === -1){
        this.$message.success("提交成功")
        this.showFormDialog = false;
        this.showAgreementDialog = false;

      }else{
        this.$message.error(message)
         this.showAgreementDialog = false;
      }

      // console.log(`提交返回值`,res)
    },
    handleButtonClick(btn,open,item){
       if(btn.url){
         return this.go(btn,open)
       }
       if(btn.action && btn.action instanceof Function){
         btn.action(item,btn)
       }
    },
    handleDialogTest(){
      this.$dialog({
        title: 'amazing',
        component: () => <Debuger />
      })
    },
    goback() {
      this.$router.go(-1);
    },
    go(e,open = false) {

      let companyName = this.userStore.adminVo && this.userStore.adminVo.companyName ? this.userStore.adminVo.companyName : this.userStore.user.companyName;
      let code = this.userStore.adminVo && this.userStore.adminVo.code ? this.userStore.adminVo.code : this.userStore.user.code;
      if (e.url) {
        if(open){
          let url = e.url.replace("$CODE",code).replace("$COMPANY_NAME",companyName)
          window.open(url);
          return;
        }

      
        // this.$alert(`根据您的综合评估情况，当前你可贷款额度约为100万元，具体以银行审核通过数据为准。`, '', {
        //   confirmButtonText: '确定',
        //   dangerouslyUseHTMLString: true,
        //   center: true,
        // }).then((result) => {
        //   });
        this.$router.push({ name: 'iframeUrl', params: { iframeUrl: `${e.url}?companyName=${companyName}&code=${code}` } });
      } else {
        this.$alert(`<img src="${e.src}" alt="" style='width:100px'/><br>正在洽谈中，敬请期待`, '', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          center: true,
        });
      }
    },
  },
  mounted() {},
  created() {
    // this.$api.findFinancialMarketApplyPage().then((res) => {
    //   this.list = res.rows;
    // });
  },
};
</script>
<style lang="scss" scoped>

/deep/ .el-message-box.messagebox {
  height: 50vh;
   .el-message-box__content{
      .el-message-box__container{
        overflow-y: scroll;
        .el-message-box__message{
            height: 50vh;
        }
      }
    }
   
}
.product-cards{
  display: flex;
  justify-content: space-between;
}
.product-card{
  background-color: white;
  padding: 50px 10px;
  margin:10px;
  box-shadow:  20px 20px 60px #d8d5d5,
             -20px -20px 60px #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  .logo{
    width: 200px;
  }
  .name{
    font-size: 24px;
    font-weight: bold;
    padding:10px 10px;
    margin-top:20px;
  text-align: center;
  }
  .desc{
    width: 100%;
    // background: red;
    color: #666;
    margin-top:5px;
    padding: 10px;
    .name{
      font-size: 14px;
      color:#222;
      text-align: left;
      display: inline-block;
      padding: 0px;
      margin: 0px;
      &::after{
        content: ":";
      }
    }
  }

  .footer{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    justify-items: end;
    flex-direction: row-reverse;
    width: 100%;
    padding: 10px 20px;
  }
}
* {
  box-sizing: border-box;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.w20 {
  width: 20%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.el-button--default {
  background: #135694;
  color: #fff;
}

.wrapper {
  padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 15px;
  margin: 0 auto;
  min-width: 1366px;

  section {
    margin-bottom: 16px;
  }

  .concent {
    height: 100%;
  }
}
.toMore {
  cursor: pointer;
}

.chartView {
  padding: 16px 20px 24px;
  background-color: #fff;
}

.chartView .top {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 16px;
}

.chartView .item-box {
  width: calc((100% - 40px) * 0.33333);
  height: 176px;
  border-radius: 0px 0px 4px 4px;
  background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 20px;
}

.chartView .item-box:nth-child(3n + 1) {
  margin-right: 0;
}

.chartView .item-box:nth-child(n + 4) {
  margin-bottom: 0;
}

.chartView .item-box .item-top {
  border-top: 2px solid #4393f8;
  margin-bottom: 16px;
  position: relative;
}

.chartView .item-box .item-top .top-bg {
  height: 0px;
  width: 172px;
  border-top: 40px solid #4393f8;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -96px;
}

.chartView .center p {
  line-height: 30px;
}

.chartView .item-box .item-bottom {
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  margin-top: 22px;
  background: #cee4ff;
  border-radius: 0px 0px 4px 4px;
}

.chartView .top .time {
  font-size: 14px;
  color: #666666;
  text-align: right;
  line-height: 20px;
  font-weight: 600;
}

.chartView .top .img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
}

.section3 .chartView .top .img,
.section4 .chartView .top .img {
  margin-top: 10px;
}

.chartView .item-box .item-title {
  height: 40px;
  line-height: 40px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  z-index: 1;
  position: relative;
}

.concent .title {
  font-family: 'MicrosoftYaHei-Bold';
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
  position: relative;
  padding-left: 14px;
}

.concent .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4393f8;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: 0;
}

.section3 .center .pd-item {
  width: calc((100% - 22px) * 0.5);
  height: 135px;
  background: #fafafa;
  padding: 20px;
}

.section3 .center .pd-item {
  margin-right: 22px;
  margin-bottom: 22px;
}

.section3 .center .pd-item:nth-child(2n) {
  margin-right: 0;
}

.section3 .center .pd-item:nth-child(n + 3) {
  margin-bottom: 0;
}

.section3 .center .pd-item .pic {
  width: 119px;
  height: 119px;
  margin-right: 25px;
  background-color: #4393f8;
}

.section3 .center .pd-item .pic img {
  width: 100%;
  height: 100%;
}

.section3 .center .pd-item .tl {
  margin: 12px 0;
}

.section3 .center .pd-item .tl-bg {
  display: inline-block;
  padding: 3px 13px;
  color: #fff;
  margin-right: 11px;
  position: relative;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  z-index: 0;
}

.section3 .center .pd-item .tl-bg:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #5583cc;
  transform: skew(-10deg);
  border-radius: 4px;
}

.section3 .center .pd-item .xy:before {
  background: #ffa75c;
}

.section3 .center .pd-item .text {
  width: calc(100% - 144px);
}

.section3 .center .pd-item .text .num {
  font-size: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 28px;
}

.section3 .center .pd-item .text .dw {
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
}

.chartView {
  height: 100%;
}

.chartView .bank-icon {
  text-align: center;
  height: 150px;
  margin-bottom: 10px;
}
.chartView .bank-icon img {
  width: 150px;
  cursor: pointer;
}
.finance-info {
  margin: 16px 0;
  background-color: #fff;
}

.header {
  font-size: 26px;
  color: #000000;
  line-height: 74px;
  text-indent: 14px;
  border-bottom: 1px solid #dddddd;
  position: relative;
  margin: 0 20px;
}
.header::before {
  content: '';
  background: #4393f8;
  border-radius: 3px;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 27px;
  left: 0;
}
.info-content {
  padding: 16px 20px;
  padding-top: 0;
}

.btn {
  height: 40px;
  line-height: 40px;
}

.btn-sub {
  color: #666;
  margin-left: 12px;
}

.enterprise-item {
  background: #fafafa;
  padding: 0 16px;
  margin-top: 16px;
}

.enterprise-item .name {
  font-size: 14px;
  color: #0e459c;
  line-height: 40px;
  border-bottom: 1px dashed #dddddd;
  cursor: pointer;
}

.enterprise-item .name span {
  font-weight: 600;
}

.enterprise-item .item-info {
  display: flex;
  font-size: 14px;
  color: #999999;
  line-height: 50px;
}

.enterprise-item .item-info .text {
  color: #151515;
}

.blue {
  color: #5babfe;
}
</style>